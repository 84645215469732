import { mergeClasses } from '@expo/styleguide';

import { CALLOUT } from '~/ui/components/text';

type Props = {
  className?: string;
  percentComplete: number;
  loop?: boolean;
  bottomLeftText?: string;
  bottomRightText?: string;
};

export function ProgressBar({
  className,
  percentComplete,
  loop,
  bottomLeftText,
  bottomRightText,
}: Props) {
  return (
    <div className={mergeClasses('relative mt-3 flex w-full max-w-[400px] flex-1', className)}>
      <div className="flex h-2 w-full flex-1 rounded-full border border-default bg-element" />
      <div
        className="absolute top-0 flex h-2 w-full min-w-[10px] flex-1 rounded-full bg-palette-blue9"
        style={{
          width: `${loop ? 100 : percentComplete}%`,
        }}>
        {loop && (
          <div className="h-2 w-full animate-progressBar rounded-full bg-progressbar-pattern" />
        )}
      </div>
      {bottomLeftText || bottomRightText ? (
        <div className="absolute flex w-full justify-between p-1">
          {bottomLeftText && (
            <span className="absolute left-0">
              <CALLOUT theme="tertiary">{bottomLeftText ?? '0%'}</CALLOUT>
            </span>
          )}
          {bottomRightText && (
            <span className="absolute right-0">
              <CALLOUT theme="tertiary">{bottomRightText ?? '100%'}</CALLOUT>
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
}
