// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EstimateUsageAndCostForBillingPeriodQueryVariables = Types.Exact<{
  accountId: Types.Scalars['String']['input'];
  currentDate: Types.Scalars['DateTime']['input'];
}>;


export type EstimateUsageAndCostForBillingPeriodQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byId: { __typename?: 'Account', usageMetrics: { __typename?: 'AccountUsageMetrics', EAS_BUILD: { __typename?: 'UsageMetricTotal', id: string, totalCost: number, planMetrics: Array<{ __typename?: 'EstimatedUsage', id: string, service: Types.EasService, serviceMetric: Types.EasServiceMetric, metricType: Types.UsageMetricType, value: number, limit: number }>, overageMetrics: Array<{ __typename?: 'EstimatedOverageAndCost', id: string, service: Types.EasService, serviceMetric: Types.EasServiceMetric, metricType: Types.UsageMetricType, value: number, totalCost: number, limit: number, metadata?: { __typename: 'AccountUsageEASBuildMetadata', platform?: Types.AppPlatform | null, billingResourceClass?: Types.EasBuildBillingResourceClass | null, waiverType?: Types.EasBuildWaiverType | null } | { __typename: 'AccountUsageEASJobsMetadata', resourceClassDisplayName: string } | null }>, billingPeriod: { __typename?: 'BillingPeriod', id: string, anchor: any, start: any, end: any } }, EAS_UPDATE: { __typename?: 'UsageMetricTotal', planMetrics: Array<{ __typename?: 'EstimatedUsage', serviceMetric: Types.EasServiceMetric, limit: number, value: number }> } } } } };

export type UsageEstimateForBillingPeriodFragment = { __typename?: 'UsageMetricTotal', id: string, totalCost: number, planMetrics: Array<{ __typename?: 'EstimatedUsage', id: string, service: Types.EasService, serviceMetric: Types.EasServiceMetric, metricType: Types.UsageMetricType, value: number, limit: number }>, overageMetrics: Array<{ __typename?: 'EstimatedOverageAndCost', id: string, service: Types.EasService, serviceMetric: Types.EasServiceMetric, metricType: Types.UsageMetricType, value: number, totalCost: number, limit: number, metadata?: { __typename: 'AccountUsageEASBuildMetadata', platform?: Types.AppPlatform | null, billingResourceClass?: Types.EasBuildBillingResourceClass | null, waiverType?: Types.EasBuildWaiverType | null } | { __typename: 'AccountUsageEASJobsMetadata', resourceClassDisplayName: string } | null }>, billingPeriod: { __typename?: 'BillingPeriod', id: string, anchor: any, start: any, end: any } };

export const UsageEstimateForBillingPeriodFragmentDoc = gql`
    fragment UsageEstimateForBillingPeriod on UsageMetricTotal {
  id
  totalCost
  planMetrics {
    id
    service
    serviceMetric
    metricType
    value
    limit
  }
  overageMetrics {
    id
    service
    serviceMetric
    metricType
    value
    totalCost
    limit
    metadata {
      __typename
      ... on AccountUsageEASBuildMetadata {
        platform
        billingResourceClass
        waiverType
      }
      ... on AccountUsageEASJobsMetadata {
        resourceClassDisplayName
      }
    }
  }
  billingPeriod {
    id
    anchor
    start
    end
  }
}
    `;
export const EstimateUsageAndCostForBillingPeriodDocument = gql`
    query EstimateUsageAndCostForBillingPeriod($accountId: String!, $currentDate: DateTime!) {
  account {
    byId(accountId: $accountId) {
      usageMetrics {
        EAS_BUILD: byBillingPeriod(date: $currentDate, service: BUILDS) {
          ...UsageEstimateForBillingPeriod
        }
        EAS_UPDATE: byBillingPeriod(date: $currentDate, service: UPDATES) {
          planMetrics {
            serviceMetric
            limit
            value
          }
        }
      }
    }
  }
}
    ${UsageEstimateForBillingPeriodFragmentDoc}`;
export function useEstimateUsageAndCostForBillingPeriodQuery(baseOptions: Apollo.QueryHookOptions<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables> & ({ variables: EstimateUsageAndCostForBillingPeriodQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables>(EstimateUsageAndCostForBillingPeriodDocument, options);
      }
export function useEstimateUsageAndCostForBillingPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables>(EstimateUsageAndCostForBillingPeriodDocument, options);
        }
export function useEstimateUsageAndCostForBillingPeriodSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables>(EstimateUsageAndCostForBillingPeriodDocument, options);
        }
export type EstimateUsageAndCostForBillingPeriodQueryHookResult = ReturnType<typeof useEstimateUsageAndCostForBillingPeriodQuery>;
export type EstimateUsageAndCostForBillingPeriodLazyQueryHookResult = ReturnType<typeof useEstimateUsageAndCostForBillingPeriodLazyQuery>;
export type EstimateUsageAndCostForBillingPeriodSuspenseQueryHookResult = ReturnType<typeof useEstimateUsageAndCostForBillingPeriodSuspenseQuery>;
export type EstimateUsageAndCostForBillingPeriodQueryResult = Apollo.QueryResult<EstimateUsageAndCostForBillingPeriodQuery, EstimateUsageAndCostForBillingPeriodQueryVariables>;
export function refetchEstimateUsageAndCostForBillingPeriodQuery(variables: EstimateUsageAndCostForBillingPeriodQueryVariables) {
      return { query: EstimateUsageAndCostForBillingPeriodDocument, variables: variables }
    }