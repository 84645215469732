import { Button, LinkBase } from '@expo/styleguide';
import { DotsVerticalIcon } from '@expo/styleguide-icons/outline/DotsVerticalIcon';
import { Pin02Icon } from '@expo/styleguide-icons/outline/Pin02Icon';
import { Settings01Icon } from '@expo/styleguide-icons/outline/Settings01Icon';
import { Pin02SolidIcon } from '@expo/styleguide-icons/solid/Pin02SolidIcon';

import { getProjectURL } from '~/common/helpers';
import { AppDataFragment } from '~/graphql/types.generated';
import { Dropdown } from '~/ui/components/Dropdown';
import { DropdownItem } from '~/ui/components/Dropdown/DropdownItem';
import { ProjectIcon } from '~/ui/components/ProjectIcon';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { FOOTNOTE } from '~/ui/components/text';

type ProjectRowProps = {
  accountName: string;
  app: AppDataFragment;
  isPinned?: boolean;
  setIsPinned: (isPinned: boolean) => void;
};

export function RecentProjectRow({ accountName, app, isPinned, setIsPinned }: ProjectRowProps) {
  return (
    <>
      <TableSeparator />
      <TableRow>
        <LinkBase href={getProjectURL(accountName, app.slug)}>
          <TableCell theme="interactive" className="flex h-14 flex-1 items-center gap-3 pl-3.5">
            <ProjectIcon isPinned={isPinned} name={app.name} iconUrl={app.icon?.url} />
            <div className="flex flex-col">
              <TableCellText weight="medium">{app.name}</TableCellText>
              <FOOTNOTE theme="secondary">{app.slug}</FOOTNOTE>
            </div>
          </TableCell>
        </LinkBase>
        <TableCell>
          <Dropdown
            className="min-w-[180px]"
            trigger={
              <Button theme="quaternary" leftSlot={<DotsVerticalIcon className="icon-md" />} />
            }>
            <DropdownItem
              label={isPinned ? 'Unpin project' : 'Pin project'}
              Icon={isPinned ? Pin02SolidIcon : Pin02Icon}
              onSelect={() => {
                setIsPinned(!isPinned);
              }}
            />
            <DropdownItem
              label="Project settings"
              Icon={Settings01Icon}
              href={getProjectURL(accountName, app.slug, 'settings')}
            />
          </Dropdown>
        </TableCell>
      </TableRow>
    </>
  );
}
