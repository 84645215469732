import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { PropsWithChildren } from 'react';

import { Button } from '~/ui/components/Button';
import { FormStates } from '~/ui/components/form/FormStates';

import { DialogContent } from '../DialogContent';
import { DialogContentContainer } from '../DialogContentContainer';
import { DialogFooter } from '../DialogFooter';
import { DialogRoot } from '../DialogRoot';
import { DialogTitle } from '../DialogTitle';

type Props = PropsWithChildren<{
  title?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  destructive?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  isOpen: boolean;
  status?: FormStates;
}>;

export function ConfirmationDialog({
  children,
  title,
  cancelButtonLabel,
  confirmButtonLabel,
  destructive = false,
  onCancel,
  isOpen,
  onConfirm,
  status,
}: Props) {
  return (
    <DialogRoot open={isOpen} onOpenChange={onCancel}>
      <DialogContent className="border border-default">
        {title ? (
          <DialogTitle title={title} />
        ) : (
          <VisuallyHidden asChild>
            <DialogTitle title="Confirmation dialog" />
          </VisuallyHidden>
        )}
        <DialogContentContainer>{children}</DialogContentContainer>
        <DialogFooter>
          {onCancel && (
            <Button testID="modal-cancel-button" size="sm" theme="quaternary" onClick={onCancel}>
              {cancelButtonLabel ?? 'Cancel'}
            </Button>
          )}
          <Button
            testID="confirmButton"
            size="sm"
            theme={destructive ? 'primary-destructive' : 'primary'}
            onClick={onConfirm}
            status={status}>
            {confirmButtonLabel ?? 'Confirm'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
