export function PlaceholderIllustration() {
  return (
    <svg
      width="186"
      height="186"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="98" cy="96" r="52" className="stroke-palette-gray4" strokeWidth="1.5" />
      <circle cx="98" cy="96" r="66" className="stroke-palette-gray4" strokeWidth="2" />
      <circle cx="98" cy="96" r="80" className="stroke-palette-gray3" strokeWidth="2.5" />
      <circle cx="98" cy="96" r="94" className="stroke-palette-gray2" strokeWidth="3" />
    </svg>
  );
}
