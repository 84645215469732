import { ReactNode } from 'react';

import { Avatar } from '~/ui/components/Avatar';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { CALLOUT } from '~/ui/components/text';

import { ActivityTimestamp } from './ActivityTimestamp';
import { ActorTimelineDisplayName } from './ActorAttribution';
import { Line } from './Line';

type ActivityLine = 'default' | 'faded' | 'none';

export function Activity({
  icon,
  actor,
  attribution,
  timestamp,
  accountName,
  line,
  activityBox,
}: {
  actor?: any;
  timestamp: string;
  attribution: ReactNode;
  icon: ReactNode;
  accountName: string;
  activityBox: ReactNode;
  line: ActivityLine;
}) {
  const profilePhoto = actor?.__typename === 'User' ? actor?.profilePhoto : undefined;

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-4">
        <div className="z-10 flex size-10 shrink-0 items-center justify-center self-center rounded-full border border-default bg-default">
          {icon}
        </div>
        <div className="flex flex-1 flex-wrap items-center gap-1 max-md-gutters:mb-1">
          <Avatar
            size="xs"
            name={actor?.displayName}
            profilePhoto={profilePhoto}
            className="!icon-md mr-0.5"
          />
          <ActorTimelineDisplayName actor={actor} accountName={accountName} />
          {attribution}
          <CALLOUT theme="secondary"> · </CALLOUT>
          <ActivityTimestamp timestamp={timestamp} />
        </div>
      </div>
      <div className="flex gap-4">
        <Line line={line} />
        <div className="w-full">{activityBox}</div>
      </div>
    </div>
  );
}

export function ActivitySkeleton({ line }: { line: ActivityLine }) {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-4">
        <ContentLoading
          loading
          height={40}
          width={40}
          containerClassName="shrink-0 rounded-full"
          className="rounded-full"
        />
        <div className="flex flex-1 flex-wrap items-center gap-1 max-md-gutters:mb-1">
          <ContentLoading
            loading
            height={20}
            width={20}
            className="rounded-full"
            containerClassName="shrink-0 mr-0.5"
          />
          <ContentLoading loading height={12} width={80} />
          <ContentLoading loading height={12} width={200} />
          <ContentLoading loading height={4} width={4} />
          <ContentLoading loading height={12} width={150} />
        </div>
      </div>
      <div className="flex gap-4">
        <Line line={line} />
        <div className="w-full">
          <ContentLoading loading height={103} width="100%" />
        </div>
      </div>
    </div>
  );
}
