import { DashboardOverviewScene } from '~/scenes/Dashboard/Overview';
import { HomeScene } from '~/scenes/HomeScene';
import Document from '~/ui/components/Document';
import { StructuredData } from '~/ui/components/StructuredData';

import { PageProps } from '../_app/helpers';

export type IndexSceneProps = PageProps & {
  showHome?: boolean;
};

export function IndexScene({
  currentUser,
  accountName,
  showHome,
  isYou,
  currentDateAsString,
}: IndexSceneProps) {
  if (!currentUser || !accountName || showHome) {
    return (
      <Document meta={{ name: 'default' }} disableGraphQL className="overflow-x-hidden">
        <StructuredData
          data={{
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'Expo',
            url: 'https://expo.dev/',
          }}
        />
        <HomeScene />
      </Document>
    );
  }

  return (
    <Document meta={{ name: 'dashboard' }}>
      <DashboardOverviewScene
        accountName={accountName}
        currentUser={currentUser}
        isYou={isYou}
        currentDateAsString={currentDateAsString}
      />
    </Document>
  );
}
