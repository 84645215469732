import { TableHeader } from '~/ui/components/Table/TableHeader';
import { TableHeaderCell } from '~/ui/components/Table/TableHeaderCell';
import { TableHeaderCellText } from '~/ui/components/Table/TableHeaderCellText';

type SubmissionsHeaderProps = {
  hideAuthor?: boolean;
};

export function SubmissionsHeader({ hideAuthor }: SubmissionsHeaderProps) {
  return (
    <TableHeader>
      <TableHeaderCell>
        <TableHeaderCellText>Submission</TableHeaderCellText>
      </TableHeaderCell>
      {!hideAuthor && (
        <TableHeaderCell hideOnMobile>
          <TableHeaderCellText>Created by</TableHeaderCellText>
        </TableHeaderCell>
      )}
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>Version</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>Build</TableHeaderCellText>
      </TableHeaderCell>
    </TableHeader>
  );
}
