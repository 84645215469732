import { SnackLogo } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { RefreshCcw02Icon } from '@expo/styleguide-icons/outline/RefreshCcw02Icon';

import { useSnacksQuery } from '~/graphql/queries/SnacksQuery.query.generated';
import { PlaceholderIllustration } from '~/scenes/Dashboard/Snacks/PlaceholderIllustration';
import { SnackRow } from '~/scenes/Dashboard/Snacks/SnackRow';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { Button } from '~/ui/components/Button';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { Placeholder } from '~/ui/components/Placeholder';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableHeader } from '~/ui/components/Table/TableHeader';
import { TableHeaderCell } from '~/ui/components/Table/TableHeaderCell';
import { TableHeaderCellText } from '~/ui/components/Table/TableHeaderCellText';
import { TableRoot } from '~/ui/components/Table/TableRoot';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';

type Props = {
  accountName: string;
};

export function RecentSnacks({ accountName }: Props) {
  const { loading, data, error, refetch } = useSnacksQuery({
    variables: {
      offset: 0,
      limit: 5,
      accountName,
    },
  });
  const recentSnacks = data?.account.byName.snacks ?? [];
  const hasSnacks = recentSnacks.length !== 0;

  return (
    <BoxWrapper>
      <BoxContentContainer size="header">
        <BoxHeader
          title="Recent Snacks"
          icon={<SnackLogo />}
          size="sm"
          rightSlot={
            <Button
              disabled={!hasSnacks}
              theme="quaternary"
              rightSlot={<ArrowRightIcon />}
              href={`/accounts/${accountName}/snacks`}>
              All Snacks
            </Button>
          }
        />
      </BoxContentContainer>
      {error && (
        <Placeholder
          loading={loading}
          description="Something went wrong while loading your Snacks. Try reloading the data."
          buttonText="Reload"
          inline
          onClick={async () => await refetch()}
          buttonIcon={<RefreshCcw02Icon />}
        />
      )}
      {!loading && !hasSnacks && (
        <Placeholder
          inline
          illustration={<PlaceholderIllustration />}
          icon={<SnackLogo className="icon-2xl" />}
          title="Create a Snack"
          description="Try out Expo in your browser and share your code with others using Snack."
          buttonText="Create a Snack"
          href={process.env.SNACK_SERVER_URL}
          className="mx-6"
        />
      )}
      {!error && hasSnacks && (
        <TableRoot columnCount={3} theme="unstyled">
          <TableSeparator />
          <TableHeader theme="secondary">
            <TableHeaderCell className="pl-6">
              <TableHeaderCellText>Snack</TableHeaderCellText>
            </TableHeaderCell>
            <TableHeaderCell>
              <TableHeaderCellText>Last saved</TableHeaderCellText>
            </TableHeaderCell>
            <TableHeaderCell />
          </TableHeader>

          {loading ? (
            <>
              <SnackSkeletonRow />
              <SnackSkeletonRow />
              <SnackSkeletonRow />
              <SnackSkeletonRow />
              <SnackSkeletonRow />
            </>
          ) : (
            recentSnacks.map((snack) => (
              <SnackRow
                key={snack.id}
                snack={snack}
                refetch={async () => {
                  await refetch({
                    offset: 0,
                    limit: 5,
                    accountName,
                  });
                }}
              />
            ))
          )}
        </TableRoot>
      )}
    </BoxWrapper>
  );
}

function SnackSkeletonRow() {
  return (
    <>
      <TableSeparator />
      <TableRow>
        <TableCell>
          <ContentLoading height={54} width="100%" loading />
        </TableCell>
        <TableCell>
          <ContentLoading height={54} width={148} loading />
        </TableCell>
        <TableCell>
          <ContentLoading height={36} width={36} loading />
        </TableCell>
      </TableRow>
    </>
  );
}
