// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { AppDataFragmentDoc } from '../../../../../graphql/fragments/AppData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecentProjectsQueryVariables = Types.Exact<{
  accountName: Types.Scalars['String']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.AccountAppsFilterInput>;
}>;


export type RecentProjectsQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', id: string, appsPaginated: { __typename?: 'AccountAppsConnection', edges: Array<{ __typename?: 'AccountAppsEdge', node: { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } } };


export const RecentProjectsQueryDocument = gql`
    query RecentProjectsQuery($accountName: String!, $after: String, $first: Int, $before: String, $last: Int, $filter: AccountAppsFilterInput) {
  account {
    byName(accountName: $accountName) {
      id
      appsPaginated(
        after: $after
        first: $first
        before: $before
        last: $last
        filter: $filter
      ) {
        edges {
          node {
            ...AppData
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
}
    ${AppDataFragmentDoc}`;
export function useRecentProjectsQuery(baseOptions: Apollo.QueryHookOptions<RecentProjectsQuery, RecentProjectsQueryVariables> & ({ variables: RecentProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentProjectsQuery, RecentProjectsQueryVariables>(RecentProjectsQueryDocument, options);
      }
export function useRecentProjectsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentProjectsQuery, RecentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentProjectsQuery, RecentProjectsQueryVariables>(RecentProjectsQueryDocument, options);
        }
export function useRecentProjectsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecentProjectsQuery, RecentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecentProjectsQuery, RecentProjectsQueryVariables>(RecentProjectsQueryDocument, options);
        }
export type RecentProjectsQueryHookResult = ReturnType<typeof useRecentProjectsQuery>;
export type RecentProjectsQueryLazyQueryHookResult = ReturnType<typeof useRecentProjectsQueryLazyQuery>;
export type RecentProjectsQuerySuspenseQueryHookResult = ReturnType<typeof useRecentProjectsQuerySuspenseQuery>;
export type RecentProjectsQueryQueryResult = Apollo.QueryResult<RecentProjectsQuery, RecentProjectsQueryVariables>;
export function refetchRecentProjectsQuery(variables: RecentProjectsQueryVariables) {
      return { query: RecentProjectsQueryDocument, variables: variables }
    }