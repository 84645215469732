import { Button } from '~/ui/components/Button';
import { FormStates } from '~/ui/components/form/FormStates';

type Props = {
  onClick: () => void;
  isLoading: boolean;
  label?: string;
};

export function LoadMoreButton({ onClick, isLoading, label }: Props) {
  return (
    <Button
      onClick={onClick}
      status={isLoading ? FormStates.LOADING : FormStates.IDLE}
      theme="secondary"
      size="sm"
      className="mx-auto">
      {label ?? 'Load More'}
    </Button>
  );
}
