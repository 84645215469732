import * as Analytics from '~/common/analytics';
import {
  AccountTimelineActivityQuery,
  useAccountTimelineActivityQuery,
} from '~/graphql/queries/AccountTimelineActivityQuery.generated';

import { BuildActivity } from './BuildActivity';
import { GroupedUpdateActivity } from './GroupedUpdateActivity';
import { SubmissionActivity } from './SubmissionActivity';
import {
  Activity,
  combineUpdatesIntoGroup,
  isBuildActivity,
  isGroupedUpdateActivity,
  isSubmissionActivity,
} from './helpers';
import { LoadMoreButton } from '../LoadMoreButton';

function handleBuildClicked() {
  Analytics.track(Analytics.events.DASHBOARD_OVERVIEW_BUILD_CLICKED);
}

type ActivityTimelineProps = {
  data: AccountTimelineActivityQuery;
  loading: boolean;
  accountName: string;
  fetchMore: ReturnType<typeof useAccountTimelineActivityQuery>['fetchMore'];
};

export function ActivityTimeline({ data, loading, accountName, fetchMore }: ActivityTimelineProps) {
  const { edges, pageInfo } = data?.account.byName.timelineActivity;

  const onLoadMoreAsync = async () => {
    await fetchMore({ variables: { after: pageInfo.endCursor } });
  };

  const list = combineUpdatesIntoGroup(
    edges.map((edge) => edge.node).map((activity) => activity) as Activity[]
  );

  return (
    <>
      <div className="flex flex-col gap-6">
        {list.map((singleOrGroupedActivity, index) => {
          let line: 'none' | 'default' | 'faded';
          if (index !== list.length - 1) {
            line = 'default';
          } else if (pageInfo.hasNextPage) {
            line = 'faded';
          } else {
            line = 'none';
          }

          if (isBuildActivity(singleOrGroupedActivity)) {
            return (
              <BuildActivity
                key={singleOrGroupedActivity.id}
                activity={singleOrGroupedActivity}
                accountName={accountName}
                onBuildClicked={handleBuildClicked}
                line={line}
              />
            );
          }

          if (isGroupedUpdateActivity(singleOrGroupedActivity)) {
            const update = singleOrGroupedActivity[0]; // arbitrary update from the group
            return (
              <GroupedUpdateActivity
                key={update.id}
                groupedActivity={singleOrGroupedActivity}
                accountName={accountName}
                line={line}
              />
            );
          }

          if (isSubmissionActivity(singleOrGroupedActivity)) {
            return (
              <SubmissionActivity
                key={singleOrGroupedActivity.id}
                activity={singleOrGroupedActivity}
                accountName={accountName}
                line={line}
              />
            );
          }

          return null;
        })}
      </div>
      {pageInfo.hasNextPage && (
        <div className="flex justify-center">
          <LoadMoreButton isLoading={loading} onClick={onLoadMoreAsync} />
        </div>
      )}
    </>
  );
}
