import { Button } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';

import { AccountAppsSortByField } from '~/graphql/types.generated';
import { PROJECTS_PAGE_SIZE } from '~/pages/accounts/[account]/projects';
import { useRecentProjectsQuery } from '~/scenes/Dashboard/Overview/components/RecentProjects/RecentProjects.query.generated';
import { usePinnedApps } from '~/scenes/Dashboard/Projects/AllProjectsScene/usePinnedApps';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { Placeholder } from '~/ui/components/Placeholder';
import { TableRoot } from '~/ui/components/Table/TableRoot';

import { RecentProjectRow } from './RecentProjectRow';
import { RecentProjectRowSkeleton } from './RecentProjectRowSkeleton';

type Props = {
  accountName: string;
};

const MAX_PROJECTS = 3;

export function RecentProjects({ accountName }: Props) {
  const { data, loading: isLoadingRecentApps } = useRecentProjectsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      accountName,
      first: PROJECTS_PAGE_SIZE,
      filter: {
        sortByField: AccountAppsSortByField.LatestActivityTime,
      },
    },
  });
  const { loading: isLoadingPinnedApps, pinnedApps, setIsPinned } = usePinnedApps(accountName);

  const apps = data?.account.byName.appsPaginated.edges.map((edge) => edge.node) ?? [];
  const countOfOtherProjects = Math.max(0, MAX_PROJECTS - pinnedApps.length);
  const pinnedAppIdsSet = new Set(pinnedApps.map((app) => app.id));

  const isLoading = isLoadingRecentApps || isLoadingPinnedApps;

  return (
    <BoxWrapper className="overflow-hidden rounded-lg">
      <BoxContentContainer size="header">
        <BoxHeader
          size="sm"
          title="Projects"
          icon={<FolderIcon />}
          className="!flex-row flex-wrap"
          rightSlot={
            <Button
              theme="quaternary"
              rightSlot={<ArrowRightIcon />}
              href={`/accounts/${accountName}/projects`}>
              All projects
            </Button>
          }
        />
      </BoxContentContainer>
      {!isLoading && apps.length === 0 && (
        <Placeholder
          title="No projects"
          description="Create a project to get started with Expo"
          className="flex h-[195px] items-center justify-center rounded-none rounded-b-md border-x-0 border-b-0 p-0"
          buttonText="Create a Project"
          href={`/accounts/${accountName}/projects?create=true`}
        />
      )}
      <TableRoot columnCount={2} theme="unstyled">
        {isLoading &&
          !apps.length &&
          Array.from(new Array(MAX_PROJECTS - pinnedAppIdsSet.size)).map((_, index) => (
            <RecentProjectRowSkeleton key={index} />
          ))}
        {pinnedApps.map((app) => {
          if (isLoading || !app) {
            return <RecentProjectRowSkeleton key={app.id} />;
          }

          return (
            <RecentProjectRow
              key={app.id}
              accountName={accountName}
              app={app}
              isPinned
              setIsPinned={setIsPinned(app.id)}
            />
          );
        })}
        {!isLoading &&
          apps
            .filter((app) => !pinnedAppIdsSet.has(app.id))
            .slice(0, countOfOtherProjects)
            .map((app) => (
              <RecentProjectRow
                key={app.id}
                app={app}
                accountName={accountName}
                setIsPinned={setIsPinned(app.id)}
              />
            ))}
      </TableRoot>
    </BoxWrapper>
  );
}
