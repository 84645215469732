import { Link, mergeClasses } from '@expo/styleguide';

import { getProjectURL } from '~/common/helpers';
import { ProjectIcon } from '~/ui/components/ProjectIcon';
import { CALLOUT } from '~/ui/components/text';

import { Activity } from './helpers';

type Props = {
  text: string;
  accountName?: string;
  activity?: Activity;
  hideProject?: boolean;
};

export function ActivityAttribution({ text, accountName, activity, hideProject }: Props) {
  const { name, fullName, slug, iconUrl } = activity?.app ?? {};

  const displayName = name ?? fullName;
  const showProject = !hideProject && displayName && accountName && slug;

  return (
    <>
      <CALLOUT tag="span" className={mergeClasses(showProject && 'mr-0.5')}>
        {text}
      </CALLOUT>
      {showProject && (
        <Link
          href={getProjectURL(accountName, slug)}
          className="inline-flex items-center gap-1.5 align-middle text-xs">
          <ProjectIcon
            iconUrl={iconUrl}
            name={displayName}
            iconSize={20}
            className="min-h-[20px] min-w-[20px] align-middle"
          />
          {displayName}
        </Link>
      )}
    </>
  );
}
