import { LayersTwo02Icon } from '@expo/styleguide-icons/outline/LayersTwo02Icon';

import { TableUpdateFragment } from '~/graphql/types.generated';
import { UpdateRow } from '~/scenes/UpdateListScene/UpdateRow';
import { UpdatesHeader } from '~/scenes/UpdateListScene/UpdatesHeader';
import { TableRoot } from '~/ui/components/Table/TableRoot';

import { Activity } from './Activity';
import { ActivityAttribution } from './ActivityAttribution';

type ActivityProps = {
  groupedActivity: TableUpdateFragment[];
  accountName: string;
  line: 'default' | 'faded' | 'none';
  hideProject?: boolean;
};

export function GroupedUpdateActivity({
  groupedActivity,
  accountName,
  line,
  hideProject,
}: ActivityProps) {
  const update = groupedActivity[0]; // an arbitrary update from the group
  return (
    <Activity
      accountName={accountName}
      actor={update.actor}
      attribution={getAccountGroupedUpdateAttribution(accountName, groupedActivity, hideProject)}
      timestamp={update.activityTimestamp}
      line={line}
      icon={<LayersTwo02Icon className="icon-lg" />}
      activityBox={
        <TableRoot
          mobileCompactMode
          columnCount={5}
          rootClassName="hocus:shadow-sm transition-shadow"
          gridTemplateColumns={['35%', '15%', '15%', '15%', '20%']}>
          <UpdatesHeader />
          <UpdateRow updateGroup={groupedActivity} hideTimestamp isActivityView />
        </TableRoot>
      }
    />
  );
}

function getAccountGroupedUpdateAttribution(
  accountName: string,
  groupedActivity: TableUpdateFragment[],
  hideProject?: boolean
) {
  const platforms = groupedActivity.map((update) => update.updatePlatform);
  const platformString =
    platforms.includes('ios') && platforms.includes('android')
      ? 'Android and iOS'
      : platforms[0].replace('android', 'Android').replace('ios', 'iOS');

  return (
    <ActivityAttribution
      text={`Created an update for ${platformString}${hideProject ? '' : ' of'}`}
      activity={groupedActivity[0]}
      accountName={accountName}
      hideProject={hideProject}
    />
  );
}
