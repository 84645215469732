// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSnackMutationVariables = Types.Exact<{
  snackId: Types.Scalars['ID']['input'];
}>;


export type DeleteSnackMutation = { __typename?: 'RootMutation', me: { __typename?: 'MeMutation', deleteSnack: { __typename?: 'Snack', id: string } } };


export const DeleteSnackMutationDocument = gql`
    mutation DeleteSnackMutation($snackId: ID!) {
  me {
    deleteSnack(snackId: $snackId) {
      id
    }
  }
}
    `;
export function useDeleteSnackMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSnackMutation, DeleteSnackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSnackMutation, DeleteSnackMutationVariables>(DeleteSnackMutationDocument, options);
      }
export type DeleteSnackMutationHookResult = ReturnType<typeof useDeleteSnackMutation>;
export type DeleteSnackMutationMutationResult = Apollo.MutationResult<DeleteSnackMutation>;
export type DeleteSnackMutationMutationOptions = Apollo.BaseMutationOptions<DeleteSnackMutation, DeleteSnackMutationVariables>;