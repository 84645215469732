import { mergeClasses } from '@expo/styleguide';

export function Line({ line }: { line: 'default' | 'faded' | 'none' }) {
  return (
    <div className="flex w-10 shrink-0 justify-center self-stretch">
      {line !== 'none' && (
        <div
          className={mergeClasses(
            'h-[calc(100%+96px)] w-px -translate-y-10 rounded-[1px] bg-palette-gray4',
            line === 'faded' && 'bg-gradient-to-b from-transparent to-default',
            line === 'faded' && 'dark:to-screen'
          )}
        />
      )}
    </div>
  );
}
