import {
  TableBuildFragment,
  TableSubmissionFragment,
  TableUpdateFragment,
} from '~/graphql/types.generated';

export type Activity = TableBuildFragment | TableUpdateFragment | TableSubmissionFragment;
type SingleOrGroupedActivity = TableBuildFragment | TableUpdateFragment[] | TableSubmissionFragment;

export function isBuildActivity(
  singleOrGroupedActivity: SingleOrGroupedActivity
): singleOrGroupedActivity is TableBuildFragment {
  return !Array.isArray(singleOrGroupedActivity) && singleOrGroupedActivity.__typename === 'Build';
}

export function isGroupedUpdateActivity(
  singleOrGroupedActivity: SingleOrGroupedActivity
): singleOrGroupedActivity is TableUpdateFragment[] {
  return (
    Array.isArray(singleOrGroupedActivity) && singleOrGroupedActivity[0]?.__typename === 'Update'
  );
}

export function isSubmissionActivity(
  singleOrGroupedActivity: SingleOrGroupedActivity
): singleOrGroupedActivity is TableSubmissionFragment {
  return (
    !Array.isArray(singleOrGroupedActivity) && singleOrGroupedActivity.__typename === 'Submission'
  );
}

export function combineUpdatesIntoGroup(list: Activity[]): SingleOrGroupedActivity[] {
  const merged: SingleOrGroupedActivity[] = [];

  for (let i = 0; i < list.length; i++) {
    const activity = list[i];
    const nextActivity = list[i + 1];

    // If there are two consecutive updates from the same group, merge them.
    if (isUpdate(activity)) {
      if (
        nextActivity &&
        isUpdate(nextActivity) &&
        i + 1 < list.length &&
        activity.group === nextActivity.group
      ) {
        merged.push([activity, nextActivity]); // a group of 2 updates

        // Skip the next element as it has already been merged
        i += 1;
      } else {
        merged.push([activity]); // a group consisting of a single update
      }
    } else {
      merged.push(activity);
    }
  }

  return merged;
}

function isUpdate(activity: Activity): activity is TableUpdateFragment {
  return activity.__typename === 'Update';
}
