import { AppleAppStoreIcon } from '@expo/styleguide-icons/custom/AppleAppStoreIcon';
import { GoogleAppStoreIcon } from '@expo/styleguide-icons/custom/GoogleAppStoreIcon';
import { ComponentType, HTMLAttributes } from 'react';

import { AppPlatform, SubmissionStatus } from '~/graphql/types.generated';

export const submissionStoreMap: Record<AppPlatform, string> = {
  [AppPlatform.Ios]: 'App Store',
  [AppPlatform.Android]: 'Play Store',
};

export const platformLabelMap: Record<AppPlatform, string> = {
  [AppPlatform.Ios]: 'iOS',
  [AppPlatform.Android]: 'Android',
};

export const buildPhasePlatformLabelMap: Record<AppPlatform, string> = {
  [AppPlatform.Ios]: 'App Store Submission',
  [AppPlatform.Android]: 'Play Store Submission',
};

export const buildPhasePlatformIDMap: Record<AppPlatform, string> = {
  [AppPlatform.Ios]: 'SUBMIT_APP_STORE',
  [AppPlatform.Android]: 'SUBMIT_PLAY_STORE',
};

export const submissionStatusLabelMap: Record<SubmissionStatus, string> = {
  [SubmissionStatus.Errored]: 'Failed',
  [SubmissionStatus.Finished]: 'Success',
  [SubmissionStatus.InProgress]: 'In Progress',
  [SubmissionStatus.InQueue]: 'Queued',
  [SubmissionStatus.AwaitingBuild]: 'Queued',
  [SubmissionStatus.Canceled]: 'Canceled',
};

export const submissionStoreIconMap: Record<
  AppPlatform,
  ComponentType<HTMLAttributes<SVGElement>>
> = {
  [AppPlatform.Ios]: AppleAppStoreIcon,
  [AppPlatform.Android]: GoogleAppStoreIcon,
};
