import { LinkBase } from '@expo/styleguide';

import { Snack } from '~/types';
import { TableCell } from '~/ui/components/Table/TableCell';
import { Tag } from '~/ui/components/Tag';
import { CALLOUT, DEMI, FOOTNOTE } from '~/ui/components/text';

type Props = {
  snack: Snack;
};

export function SnackCell({ snack }: Props) {
  return (
    <LinkBase
      href={`${process.env.SNACK_SERVER_URL}/${snack.fullName}`}
      openInNewTab
      className="grid">
      <TableCell
        theme="interactive"
        className="group flex min-h-[54px] flex-col justify-center pl-5">
        <CALLOUT tag="span">
          <DEMI>{snack.name}</DEMI>
          {snack.isDraft && (
            <Tag title="Draft" theme="blue" small className="ml-3 group-hover:shadow-xs" />
          )}
        </CALLOUT>
        {snack.description && snack.description !== 'No description' && (
          <FOOTNOTE theme="secondary" className="max-w-[66vw] truncate">
            {snack.description}
          </FOOTNOTE>
        )}
      </TableCell>
    </LinkBase>
  );
}
