// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AppDataFragmentDoc } from './AppData.fragment.generated';
export type TableSubmissionFragment = { __typename: 'Submission', id: string, activityTimestamp: any, createdAt: any, submissionPlatform: Types.AppPlatform, submissionStatus: Types.SubmissionStatus, initiatingActor?: { __typename: 'Robot', id: string, displayName: string } | { __typename: 'SSOUser', profilePhoto: string, id: string, displayName: string } | { __typename: 'User', profilePhoto: string, id: string, displayName: string } | null, app: { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null }, submittedBuild?: { __typename?: 'Build', id: string, appVersion?: string | null } | null };

export const TableSubmissionFragmentDoc = gql`
    fragment TableSubmission on Submission {
  id
  __typename
  activityTimestamp
  createdAt
  initiatingActor {
    id
    __typename
    displayName
    ... on UserActor {
      profilePhoto
    }
  }
  app {
    ...AppData
  }
  submittedBuild {
    id
    appVersion
  }
  submissionPlatform: platform
  submissionStatus: status
}
    ${AppDataFragmentDoc}`;