// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SnacksQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  accountName: Types.Scalars['String']['input'];
}>;


export type SnacksQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', id: string, snacks: Array<{ __typename?: 'Snack', id: string, hashId: string, name: string, fullName: string, description: string, username: string, isDraft: boolean, updated: any }> } } };


export const SnacksQueryDocument = gql`
    query SnacksQuery($offset: Int!, $limit: Int!, $accountName: String!) {
  account {
    byName(accountName: $accountName) {
      id
      snacks: snacks(limit: $limit, offset: $offset) {
        id
        hashId
        name
        fullName
        description
        username
        isDraft
        updated
      }
    }
  }
}
    `;
export function useSnacksQuery(baseOptions: Apollo.QueryHookOptions<SnacksQuery, SnacksQueryVariables> & ({ variables: SnacksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SnacksQuery, SnacksQueryVariables>(SnacksQueryDocument, options);
      }
export function useSnacksQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SnacksQuery, SnacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SnacksQuery, SnacksQueryVariables>(SnacksQueryDocument, options);
        }
export function useSnacksQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SnacksQuery, SnacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SnacksQuery, SnacksQueryVariables>(SnacksQueryDocument, options);
        }
export type SnacksQueryHookResult = ReturnType<typeof useSnacksQuery>;
export type SnacksQueryLazyQueryHookResult = ReturnType<typeof useSnacksQueryLazyQuery>;
export type SnacksQuerySuspenseQueryHookResult = ReturnType<typeof useSnacksQuerySuspenseQuery>;
export type SnacksQueryQueryResult = Apollo.QueryResult<SnacksQuery, SnacksQueryVariables>;
export function refetchSnacksQuery(variables: SnacksQueryVariables) {
      return { query: SnacksQueryDocument, variables: variables }
    }