import { TableBuildFragment } from '~/graphql/types.generated';
import { LinkBase } from '~/ui/components/LinkBase';
import { CALLOUT, DEMI } from '~/ui/components/text';

type ActorTimelineDisplayNameProps = {
  actor: TableBuildFragment['actor'];
  accountName: string;
};

export function ActorTimelineDisplayName({ actor, accountName }: ActorTimelineDisplayNameProps) {
  return actor ? (
    <CALLOUT tag="span">
      {accountName ? (
        <LinkBase
          isStyled
          href={`/accounts/${accountName}/settings/${
            actor.__typename === 'User' ? 'members' : 'access-tokens'
          }`}>
          <DEMI>{actor.displayName}</DEMI>
        </LinkBase>
      ) : (
        <DEMI>{actor.displayName}</DEMI>
      )}
    </CALLOUT>
  ) : (
    <CALLOUT tag="span">A deleted user</CALLOUT>
  );
}
