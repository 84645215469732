import { BookOpen02Icon } from '@expo/styleguide-icons/outline/BookOpen02Icon';
import { ReactNode } from 'react';

import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';

type OnboardingTileProps = {
  tile: ReactNode;
};

export function OnboardingTile({ tile }: OnboardingTileProps) {
  if (!tile) {
    return null;
  }

  return (
    <BoxWrapper className="col-span-2 p-4">
      <BoxHeader icon={<BookOpen02Icon />} title="Did you know…" size="sm" />
      {tile}
    </BoxWrapper>
  );
}
