import { format } from 'date-fns/format';
import { useEffect, useState } from 'react';

import { dateFnsFormats } from '~/common/constants';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { TableCellText } from '~/ui/components/Table/TableCellText';

import { TableCell, type CellProps } from './TableCell';

type Props = {
  date: string | Date;
  dateFormat?: string;
  loaderWidth?: number;
} & CellProps;

export function TableDateCell({
  date,
  dateFormat = dateFnsFormats.timestamp,
  loaderWidth = 148,
  ...rest
}: Props) {
  const [renderDate, setRenderDate] = useState(false);

  useEffect(() => {
    setRenderDate(true);
  }, []);

  return (
    <TableCell {...rest}>
      <ContentLoading loading={!renderDate} height={24} width={loaderWidth}>
        <TableCellText className="whitespace-nowrap" tag="time">
          {format(new Date(date), dateFormat)}
        </TableCellText>
      </ContentLoading>
    </TableCell>
  );
}
