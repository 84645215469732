import { Cube01Icon } from '@expo/styleguide-icons/outline/Cube01Icon';

import { getGitCommitValue } from '~/common/github-utils';
import { AppPlatform, TableBuildFragment } from '~/graphql/types.generated';
import { ActivityAttribution } from '~/scenes/Dashboard/Overview/components/ActivityTimeline/ActivityAttribution';
import { BuildRow } from '~/scenes/Dashboard/Projects/BuildsListScene/BuildRow';
import { BuildsHeader } from '~/scenes/Dashboard/Projects/BuildsListScene/BuildsHeader';
import { TableRoot } from '~/ui/components/Table/TableRoot';

import { Activity } from './Activity';

type ActivityProps = {
  activity: TableBuildFragment;
  accountName: string;
  line: 'default' | 'faded' | 'none';
  onBuildClicked?: () => void;
  hideProject?: boolean;
};

export function BuildActivity({
  activity,
  accountName,
  line,
  onBuildClicked,
  hideProject,
}: ActivityProps) {
  return (
    <Activity
      accountName={accountName}
      actor={activity.actor}
      attribution={getBuildAttribution(accountName, activity, hideProject)}
      timestamp={activity.activityTimestamp}
      line={line}
      icon={<Cube01Icon className="icon-lg" />}
      activityBox={
        <TableRoot
          mobileCompactMode
          columnCount={activity.deployment ? 4 : 5}
          gridTemplateColumns={
            activity.deployment
              ? [{ min: '35%', max: 'auto' }, '30%', '15%', '20%']
              : [{ min: '35%', max: 'auto' }, '15%', '15%', '15%', '20%']
          }
          rootClassName="hocus:shadow-sm transition-shadow">
          <BuildsHeader
            hasDeployment={!!activity.deployment}
            gitColumnName={
              // note(tchayen): This follows the logic from BuildDetail component.
              getGitCommitValue(
                activity.buildGitCommitHash,
                null,
                activity.buildIsGitWorkingTreeDirty
              )
                ? 'Commit'
                : 'Git ref'
            }
          />
          <BuildRow
            onBuildClicked={
              onBuildClicked
                ? () => {
                    onBuildClicked();
                  }
                : undefined
            }
            isActivityView
            build={activity}
            accountName={accountName}
            hideTimestamp
          />
        </TableRoot>
      }
    />
  );
}

function getBuildAttribution(
  accountName: string,
  activity: TableBuildFragment,
  hideProject?: boolean
) {
  switch (activity.buildPlatform) {
    case AppPlatform.Android:
      if (hideProject) {
        return <ActivityAttribution text="created an Android build" />;
      }
      return (
        <ActivityAttribution
          text="created an Android build of"
          accountName={accountName}
          activity={activity}
        />
      );
    case AppPlatform.Ios:
      if (hideProject) {
        return <ActivityAttribution text="created an iOS build" />;
      }
      return (
        <ActivityAttribution
          text="created an iOS build of"
          accountName={accountName}
          activity={activity}
        />
      );
    default:
      return null;
  }
}
