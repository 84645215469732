import { UserActor } from '~/graphql/types.generated';
import { Avatar } from '~/ui/components/Avatar';
import { CALLOUT, FOOTNOTE } from '~/ui/components/text';

type UserAttributionProps = {
  actor?: Partial<UserActor> | null;
  large?: boolean;
};

export function ActorAttribution({ actor, large }: UserAttributionProps) {
  const displayName = actor?.fullName ?? actor?.displayName ?? actor?.username ?? undefined;
  const profilePhoto = actor?.profilePhoto ?? undefined;
  const secondaryData = actor?.bestContactEmail ?? actor?.username ?? undefined;

  return (
    <div className="flex items-center gap-2 whitespace-nowrap">
      <Avatar profilePhoto={profilePhoto} name={displayName} size={large ? 'md' : 'xs'} />
      <div>
        <div className="flex flex-col">
          <CALLOUT weight={large ? 'medium' : 'regular'} className="whitespace-nowrap">
            {displayName}
          </CALLOUT>
          {actor?.username && displayName !== actor.username && (
            <FOOTNOTE theme="secondary" className="leading-tight">
              {actor?.username}
            </FOOTNOTE>
          )}
        </div>
        {large && secondaryData && secondaryData !== displayName && (
          <FOOTNOTE theme="secondary">{secondaryData}</FOOTNOTE>
        )}
      </div>
    </div>
  );
}
