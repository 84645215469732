import { ApolloError, useMutation } from '@apollo/client';
import { DotsVerticalIcon } from '@expo/styleguide-icons/outline/DotsVerticalIcon';
import { Trash03Icon } from '@expo/styleguide-icons/outline/Trash03Icon';
import { useState } from 'react';

import {
  DeleteSnackMutation,
  DeleteSnackMutationDocument,
  DeleteSnackMutationVariables,
} from '~/graphql/mutations/DeleteSnackMutation.mutation.generated';
import { useToast } from '~/providers/Toast';
import { Snack } from '~/types';
import { Button } from '~/ui/components/Button';
import { confirmAsync } from '~/ui/components/Dialog/ConfirmationDialog/confirmAsync';
import { Dropdown } from '~/ui/components/Dropdown';
import { DropdownItem } from '~/ui/components/Dropdown/DropdownItem';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableDateCell } from '~/ui/components/Table/TableDateCell';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { FormStates } from '~/ui/components/form/FormStates';

import { SnackCell } from './SnackCell';

type Props = {
  snack: Snack;
  refetch?: () => Promise<void>;
};

export function SnackRow({ snack, refetch }: Props) {
  const [deleteButtonState, setDeleteButtonState] = useState(FormStates.IDLE);

  const [deleteSnack] = useMutation<DeleteSnackMutation, DeleteSnackMutationVariables>(
    DeleteSnackMutationDocument
  );

  const toast = useToast();

  async function deleteSnackActionAsync() {
    const isConfirmed = await confirmAsync(
      `Are you sure you want to delete the "${snack.fullName}" Snack?`,
      {
        confirmButtonLabel: 'Delete Snack',
        destructive: true,
      }
    );

    if (!isConfirmed) {
      return;
    }

    setDeleteButtonState(FormStates.LOADING);
    try {
      toast.remove();
      await deleteSnack({ variables: { snackId: snack.id } });
      setDeleteButtonState(FormStates.SUCCESS);
      await refetch?.();
    } catch (error) {
      console.error(error);
      if (error instanceof ApolloError) {
        toast.add({
          message: error.graphQLErrors[0].message,
          theme: 'warning',
          onClose: toast.remove,
        });
      } else {
        toast.add({
          message: (error as Error).message,
          theme: 'warning',
          onClose: toast.remove,
        });
      }
      setDeleteButtonState(FormStates.IDLE);
    }
  }

  return (
    <>
      <TableSeparator />
      <TableRow>
        <SnackCell snack={snack} />
        <TableDateCell date={snack.updated} />
        <TableCell>
          <Dropdown
            className="min-w-[140px]"
            trigger={
              <Button leftSlot={<DotsVerticalIcon className="icon-md" />} theme="quaternary" />
            }>
            <DropdownItem
              destructive
              label="Delete"
              Icon={Trash03Icon}
              onSelect={deleteSnackActionAsync}
              isLoading={deleteButtonState === FormStates.LOADING}
            />
          </Dropdown>
        </TableCell>
      </TableRow>
    </>
  );
}
