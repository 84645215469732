import { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';

import { ConfirmAsyncDialogWrapper } from './ConfirmAsyncDialogWrapper';
import { ConfirmAsyncOptions } from './types';

export function confirmAsync(
  message: ReactNode,
  { title, cancelButtonLabel, confirmButtonLabel, destructive }: ConfirmAsyncOptions = {}
) {
  return new Promise<boolean>(function (resolve) {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const root = createRoot(container);

    function unmount() {
      document.body.removeChild(container);
      root.unmount();
    }

    root.render(
      <ConfirmAsyncDialogWrapper
        message={message}
        title={title}
        cancelButtonLabel={cancelButtonLabel}
        confirmButtonLabel={confirmButtonLabel}
        destructive={destructive}
        resolve={resolve}
        unmount={unmount}
      />
    );
  });
}
